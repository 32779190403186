@use "sass:map";

@import "~bootstrap/scss/functions";

@import "~devicon/devicon.min.css";

$custom-colors: (
    "theme-red": #fe5222,
);

@import "~bootstrap/scss/variables";

$theme-colors: map-merge($theme-colors, $custom-colors);

@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

// Layout & components
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/offcanvas";
@import "~bootstrap/scss/placeholders";

// Helpers
@import "~bootstrap/scss/helpers";

// Utilities
@import "~bootstrap/scss/utilities/api";


.blob-container {
  position: absolute;
  top: 0px;
  right: 0px;
  max-width: 1300px;
  z-index: -1;
  opacity: 0.8;
  overflow: hidden;

  img {
    width: 100%;
    min-width: 900px;
  }
}

.stack-icon {
  margin-right: .75rem;
  font-size: 1.75rem;
}

.tooltip-arrow {
  position: initial;
  visibility: hidden;
}

.carousel-control-prev,
.carousel-control-next {
  width: auto;
}

.carousel-control-prev {
  left: -4rem;
}

.carousel-control-next {
  right: -4rem;
}

.carousel-indicators {
  margin-bottom: -2.5rem
}

@include media-breakpoint-down(md) {
  .carousel-control-prev {
    left: -1.75rem;
  }
  
  .carousel-control-next {
    right: -1.75rem;
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.modal-close {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
  background: #ededed;
  height: 35px;
  width: 35px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.carousel-item img {
  cursor: pointer;
}